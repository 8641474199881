import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="container" style={{ flex: 1 }}>
      <section>
        <div style={{ flex: 1 }}>
          <p>
            Contact Creative Train Company by phone or email or by filling out
            the form to the right to discuss your scale trains and see how we
            can work together to fit your needs.
          </p>
          <p>
            Phone: <a href="tel:+12047249827">+1 (204) 332-0492</a>
          </p>
          <p>
            Email:{" "}
            <a href="mailto:henry@creativetrains.ca">henry@creativetrains.ca</a>
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <form
            action="https://formspree.io/abe@creativetrains.ca"
            method="post"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <input type="text" name="name" placeholder="Your name" />
            <input type="email" name="_replyto" placeholder="Your email" />
            <textarea
              name="message"
              placeholder="Write us a nice message here"
            ></textarea>
            <input type="hidden" name="_cc" value="henry@creativetrains.ca" />
            <input type="submit" value="Send" />
          </form>
        </div>
      </section>
    </div>
  </Layout>
)

export default ContactPage
